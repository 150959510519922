import React,{useState,useEffect} from 'react'
import { Button,Grid, TextField, FormLabel, FormControlLabel, Checkbox } from '@mui/material';
import './App.css';
import { useForm } from "react-hook-form";
import {apiLogin} from './services/api/accounts/Login';
import { validatePolish } from 'validate-polish';
import LanguageComponent from './components/language/LanguageComponent';
import { useTranslation } from "react-i18next";

const App = (props, match) => {
  const { t, i18n, ready } = useTranslation();
  const { register,getValues } = useForm();
  const [step, setStep] = useState(0);
  const [validationMessage, setValidationMessage] = useState('');
  const [company, setCompany] = useState(true);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isFirstChecked, setIsFirstChecked] = useState(false);
  const [isSecondChecked, setIsSecondChecked] = useState(false);
  const [isThreeChecked, setIsThreeChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenLanguage, setIsOpenLanguage] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [language, setLanguage] = useState('PL');

  const login = () =>
  {
     window.location.href='https://system.signsafe.app';
  }

  useEffect(() => {
    async function fetchData() {

        if(localStorage.getItem('Language')==='PL')
        {
            setLanguage('PL');
        }
        else if(localStorage.getItem('Language')==='EN')
        {
            setLanguage('EN');
        }
        else if(localStorage.getItem('Language')==='FR')
        {
            setLanguage('FR');
        }
    }

    fetchData();

  }, []);
  
  const showLanguage = () =>{
    setIsMenu(false);
    setIsOpenLanguage(true);
  }

  const registration = async () =>
  {
     if(!isFirstChecked || !isSecondChecked || !isThreeChecked)
     {
        setValidationMessage(t("app_text32"));
        return;
     }
     if(!company)
     {
        let firstName = getValues("name");
        if(firstName===null || firstName==='')
        {
          setValidationMessage(t("app_text33"));
          return;
        }
        let pesel = getValues("pesel");
        if(pesel===null || pesel==='')
        {
          setValidationMessage(t("app_text34"));
          return;
        }
        if (!validatePolish.pesel(pesel)) {
          setValidationMessage(t("app_text35"));
          return;
        }
        let email = getValues("email");
        if(email===null || email==='')
        {
          setValidationMessage(t("app_text36"));
          return;
        }
        let code = getValues("code");
        setIsLoading(true);
        let result = await apiLogin.registration({email:email, name:firstName, pesel: pesel, code: code});
        if(!result.error)
        {
            window.location.href='https://system.signsafe.app/login?type=2';
        }
        return;
     }
     else if(company)
     {
        let firstName = getValues("name");
        if(firstName===null || firstName==='')
        {
          setValidationMessage(t("app_text37"));
          return;
        }
        let companyName = getValues("companyName");
        if(companyName===null || companyName==='')
        {
          setValidationMessage(t("app_text38"));
          return;
        }
        let nip = getValues("nip");
        if(nip===null || nip==='')
        {
          setValidationMessage(t("app_text39"));
          return;
        }
        if (!validatePolish.nip(nip)) {
          setValidationMessage(t("app_text40"));
          return;
        }
        let email = getValues("email");
        if(email===null || email==='')
        {
          setValidationMessage(t("app_text41"));
          return;
        }

        setIsLoading(true);
        let code = getValues("code");
        
        let result = await apiLogin.registration({email:email, companyName:companyName,name:firstName, nip: nip, code: code});
        if(!result.error)
        {
            window.location.href='https://system.signsafe.app/login?type=2';
        }
        return;
     }
  }
  
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-header-logo">
          <Grid container>
            <Grid xs item>
              <a className="logo"></a>
            </Grid>
            <Grid style={{width:"210px"}} item>
              <a className="language not-mobile" onClick={()=>showLanguage()}><i className='language-svg not-mobile'></i>&nbsp;&nbsp;{language}</a>
              <Button className="login-button" onClick={()=>login()}>{t("app_text1")}</Button>
            </Grid>
          </Grid>
        </div>
      </header>
      <div className="body">
        {step===0 && 
          <div className="first-step-all">
          <div className="zigzak"></div>
          <div className="zigzak-2"></div>
          <div className="first-step">
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <div className='step-one' onClick={()=>setStep(0)}></div>
              </Grid>
              <Grid item xs={4}>
                <div className='step-two'></div>
              </Grid>
              <Grid item xs={4}>
                <div className='step-two'></div>
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <div className='earth'></div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <h5>{t("app_text2")}</h5>
              </Grid>
            </Grid>
            <br/>
            <br/>
            <Grid container>
              <Grid item style={{width:"18px"}}>
                <div className="check"></div>
              </Grid>
              <Grid item xs>
                <p>{t("app_text3")}</p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item style={{width:"18px"}}>
                <div className="check"></div>
              </Grid>
              <Grid item xs>
                <p>{t("app_text4")}</p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item style={{width:"18px"}}>
                <div className="check"></div>
              </Grid>
              <Grid item xs>
                <p>{t("app_text5")}</p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <p className='bold'>{t("app_text6")}</p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item style={{width:"18px"}}>
                <div className="check"></div>
              </Grid>
              <Grid item xs>
                <p>{t("app_text7")}</p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item style={{width:"18px"}}>
                <div className="check"></div>
              </Grid>
              <Grid item xs>
                <p>{t("app_text8")}</p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item style={{width:"18px"}}>
                <div className="check"></div>
              </Grid>
              <Grid item xs>
                <p>{t("app_text9")}</p>
              </Grid>
            </Grid>
            <br/>
            <br/>
            <Grid container>
              <Grid item xs={12}>
                <Button onClick={()=>setStep(1)}>{t("app_text10")}</Button>
              </Grid>
            </Grid>
            <br/>
            <Grid container>
              <Grid item xs={12}>
                <a className='button-2' href="https://signsafe.app/regulamin-serwisu-internetowego/" target="_blank">{t("app_text11")}</a>
              </Grid>
            </Grid>
          </div>
          </div>
        }
        {step===1 && 
          <div className="first-step-all">
          <div className="zigzak"></div>
          <div className="zigzak-2"></div>
          <div className="first-step second-step">
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <div className='step-two' onClick={()=>setStep(0)}></div>
              </Grid>
              <Grid item xs={4}>
                <div className='step-one' onClick={()=>setStep(1)}></div>
              </Grid>
              <Grid item xs={4}>
                <div className='step-two'></div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <a className='back' onClick={()=>setStep(0)}></a>
              </Grid>
              <Grid item xs={11}>
                <p className="info">2/3</p>
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <h5>{t("app_text12")}</h5>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <h6>{t("app_text13")}</h6>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <div className="box" onClick={()=>{setStep(2);setCompany(false)}}>
                  <div className='person'></div>
                  <p className='bold'>{t("app_text14b")}</p>
                  <p>{t("app_text14")}</p>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="box" onClick={()=>{setStep(2);setCompany(true)}}>
                  <div className='poeple'></div>
                  <p className='bold'>{t("app_text15b")}</p>
                  <p>{t("app_text15")}</p>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        }

        {step===2 && 
          <div className="first-step-all">
          <div className="zigzak"></div>
          <div className="zigzak-2"></div>
          <div className="first-step second-step">
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <div className='step-two' onClick={()=>setStep(0)}></div>
              </Grid>
              <Grid item xs={4}>
                <div className='step-two' onClick={()=>setStep(1)}></div>
              </Grid>
              <Grid item xs={4}>
                <div className='step-one'></div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <a className='back' onClick={()=>setStep(1)}></a>
              </Grid>
              <Grid item xs={11}>
                <p className="info">3/3</p>
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <h5>{t("app_text16")}</h5>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <h6>{t("app_text17")}</h6>
              </Grid>
            </Grid>
            {validationMessage && <Grid item xs={12}>
                <div className="validation-error">{validationMessage}</div>
              </Grid>
            }
            <Grid container>
              <Grid item xs={12}>
                  <FormLabel>{t("app_text18")}</FormLabel>
                  <TextField required autoFocus {...register("name", { required: true })} /><br />
              </Grid>
            </Grid>
            {!company && 
            <Grid container>
              <Grid item xs={12}>
                  <FormLabel>{t("app_text19")}</FormLabel>
                  <TextField required {...register("pesel", { required: true })} /><br />
              </Grid>
            </Grid>
            }
            {company && 
            <Grid container>
              <Grid item xs={12}>
                  <FormLabel>{t("app_text20")}</FormLabel>
                  <TextField required {...register("copmanyName", { required: true })} /><br />
              </Grid>
            </Grid>
            }
            {company && 
            <Grid container>
              <Grid item xs={12}>
                  <FormLabel>{t("app_text21")}</FormLabel>
                  <TextField required {...register("nip", { required: true })} /><br />
              </Grid>
            </Grid>
            }
            <Grid container>
              <Grid item xs={12}>
                  <FormLabel>{t("app_text22")}</FormLabel>
                  <TextField required {...register("email", { required: true })} /><br />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                  <FormLabel>{t("app_text23")}</FormLabel>
                  <TextField required {...register("code", { required: true })} /><br />
              </Grid>
            </Grid>
            <br/>
            <Grid container>
              <Grid item xs={12} className='bold-checkbox'>
                  <FormControlLabel
                      label={t("app_text24")}
                      control={<Checkbox checked={isAllChecked} onClick={()=>{setIsAllChecked(!isAllChecked);setIsFirstChecked(!isAllChecked);setIsSecondChecked(!isAllChecked);setIsThreeChecked(!isAllChecked);}}/>}
                  />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                  <FormControlLabel
                      label={t("app_text25")}
                      control={<Checkbox checked={isFirstChecked} onClick={()=>{setIsFirstChecked(!isFirstChecked);}}/>}
                  />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                  <FormControlLabel
                      label={t("app_text26")}
                      control={<Checkbox checked={isSecondChecked}  onClick={()=>{setIsSecondChecked(!isSecondChecked);}}/>}
                  />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                  <FormControlLabel
                      label={<span>{t("app_text27")} <a href="https://signsafe.app/regulamin-serwisu-internetowego/" target="_blank">{t("app_text28")}</a></span>}
                      control={<Checkbox checked={isThreeChecked}  onClick={()=>{setIsThreeChecked(!isThreeChecked);}}/>}
                  />
              </Grid>
            </Grid>
            <br/>
            <Grid container className='center'>
              <Grid item xs={12}>
                <Button onClick={()=>registration()}>
                {isLoading && 
                    <span className="loader"></span>
                }
                {!isLoading && 
                    <span>{t("app_text29")}</span>
                }</Button>
              </Grid>
            </Grid>
          </div>
        </div>
        }
        &nbsp;
      </div>
      
      <div className="bottom">
        <div className="App-header-logo-2">
            <Grid container>
              <Grid item xs>
                <a className="logo-2"></a>
              </Grid>
              <Grid item style={{width: "240px"}} className="mobile-center"> 
                <Grid container>
                    <Grid item xs={12}>
                        <a className="blue-dot"></a>&nbsp;{t("app_text30")}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item className='bottom-bold' xs={12}>
                    {t("app_text31")}
                    </Grid>
                  </Grid>
                </Grid>
            </Grid>
        </div>
      </div>

      <Grid container>
            <LanguageComponent setLanguage={setLanguage} language={language} isOpenLanguage={isOpenLanguage} setIsOpenLanguage={setIsOpenLanguage}></LanguageComponent>
        </Grid>
    </div>
  );
}

export default App;