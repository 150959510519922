import axios from 'axios';
import { handleResponse, handleError } from '../utilities/Response';
import { apiProvider } from '../utilities/Provider';

export class ApiLogin {
    constructor() {
        this.registration = async (model) =>{       
            return await axios
                .post(`${apiProvider.BASE_URL}/api/sync/order/put`, model, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }
    }
}

export const apiLogin = new ApiLogin({});